var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("backHead"),
      _c(
        "div",
        { staticClass: "loginbox" },
        [
          _c("div", { staticClass: "headetitle onlymar" }, [
            _vm._v("绑定手机号")
          ]),
          _c("p", { staticClass: "respasinfo" }, [
            _vm._v("为方便您之后的操作，请绑定手机号")
          ]),
          _c(
            "el-form",
            {
              ref: "RegisterForm",
              staticClass: "demo-ruleForm",
              attrs: {
                autoComplete: "on",
                model: _vm.RegisterForm,
                rules: _vm.loginRules
              }
            },
            [
              _c(
                "el-form-item",
                { staticClass: "inputstyle", attrs: { prop: "loginAccount" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "tel",
                      placeholder: "请输入手机号码",
                      maxlength: "11"
                    },
                    model: {
                      value: _vm.RegisterForm.loginAccount,
                      callback: function($$v) {
                        _vm.$set(_vm.RegisterForm, "loginAccount", $$v)
                      },
                      expression: "RegisterForm.loginAccount"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "inputstyle rel", attrs: { prop: "phoneCode" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "tel",
                      placeholder: "请输入验证码",
                      maxlength: "6"
                    },
                    model: {
                      value: _vm.RegisterForm.phoneCode,
                      callback: function($$v) {
                        _vm.$set(_vm.RegisterForm, "phoneCode", $$v)
                      },
                      expression: "RegisterForm.phoneCode"
                    }
                  }),
                  _c("b", { staticClass: "getCode" }, [_vm._v("获取验证码")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "btn_yellow",
              on: {
                click: function($event) {
                  return _vm.handleRegister("RegisterForm")
                }
              }
            },
            [_vm._v("绑定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }