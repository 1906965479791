import { Component, Vue, Model } from "vue-property-decorator";
import { phone, pas, phoneCode } from "@/utils/rule";
import backHead from "@/components/backHead";
@Component({
  components: {
    backHead
  }
})
export default class BindPhoneModel extends Vue {
  /**
   * 定义当前数据
   */
  private RegisterForm: any = {
    loginAccount: "",
    password: "",
    phoneCode: ""
  };

  /**
   * 定义当前表单验证
   */
  private loginRules: any = {
    loginAccount: [
      { required: true, trigger: "blur", validator: phone[0].validator }
    ],
    phoneCode: [
      { required: true, trigger: "blur", validator: phoneCode[0].validator }
    ],
    password: [{ required: true, trigger: "blur", validator: pas[0].validator }]
  };

  handleRegister(formName:any) {
    (this.$refs[formName] as any).validate((valid: boolean) => {
      if (valid) {
        alert("submit!");
      } else {
        return false;
      }
    });
  }
}
